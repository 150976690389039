export const Loading = () => {
  return (
    <div className="w-full h-screen flex items-center justify-center overflow-hidden">
      <span className="text-primary-royal animate-spin">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 22C17.0847 22 21.4286 17.9715 21.948 12.9983C22.0054 12.449 21.5523 12 21 12C20.4477 12 20.0067 12.45 19.935 12.9976C19.4265 16.8843 15.9999 20 12 20C7.663 20 4 16.337 4 12C4 8.001 7.11574 4.57361 11.0024 4.06499C11.55 3.99333 12 3.55228 12 3C12 2.44772 11.551 1.99461 11.0017 2.05199C6.02846 2.57152 2 6.91626 2 12C2 17.421 6.579 22 12 22Z"
            fill="#1C1C93"
          />
        </svg>
      </span>
    </div>
  );
};
