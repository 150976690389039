import { FC } from "react";
import { CustomIconProps } from "../../../types/interfaces";

export const CustomIcon: FC<CustomIconProps> = ({
  icon,
  color,
  bgColor,
  rounded,
  size,
}) => {
  const sizeStyle =
    size === "sm"
      ? "h-9 w-9"
      : size === "md"
      ? "h-10 w-10"
      : size === "lg"
      ? "h-12 w-12"
      : "h-10 w-10";

  const roundedStyle =
    rounded === "sm"
      ? "rounded-lg"
      : rounded === "pill"
      ? "rounded-full"
      : rounded === "lg"
      ? "rounded-xl"
      : "rounded-full";

  return (
    <span
      className={`${color} flex items-center justify-center flex-none ${
        bgColor ? bgColor : "bg-white/50"
      } ${sizeStyle} ${roundedStyle}`}
    >
      {icon}
    </span>
  );
};
