import { FC, Fragment } from "react";
import { LayoutProps } from "../../../types/interfaces";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <Navbar />
      <main className="font-poppins">{children}</main>
      <Footer />
    </Fragment>
  );
};
