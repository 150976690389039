import { FC, useState } from "react";
import { NavItem, SubNavItem } from "../../types/interfaces";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";

export const DesktopNavItem: FC<NavItem> = ({ title, subNavList }) => {
  const [openSubList, setOpenSubList] = useState<boolean>(false);

  const handleOpenSubList = () => {
    setOpenSubList(true);
  };
  const handleCloseSubList = () => {
    setOpenSubList(false);
  };

  return (
    <li
      className="w-full cursor-pointer p-3 flex items-center gap-2 rounded-lg relative hover:bg-hover"
      onMouseEnter={handleOpenSubList}
      onMouseLeave={handleCloseSubList}
    >
      <span className="text-support-black font-medium text-base leading-4 capitalize">
        {title}
      </span>
      {openSubList ? (
        <span className="text-support-black">
          <KeyboardArrowUpIcon fontSize="small" />
        </span>
      ) : (
        <span className="text-support-black">
          <KeyboardArrowDownIcon fontSize="small" />
        </span>
      )}
      {openSubList && <SubNav items={subNavList} />}
    </li>
  );
};

const SubNav: FC<{ items: SubNavItem[] }> = ({ items }) => {
  return (
    <div className="absolute inset-x-0 top-0 z-50" dir="ltr">
      <div className="mt-16 w-[230px] h-max rounded-lg flex flex-col overflow-hidden shadow-nav bg-transparent">
        {items.map((item) => (
          <Link
            to={item.to}
            key={item.title}
            className="w-full p-4 bg-white hover:bg-hover flex items-center text-primary-text-body text-sm tracking-[1.4px] capitalize font-medium z-50"
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};
