import { FC } from "react";
import { NavItem } from "../../../types/interfaces";
import { Link } from "react-router-dom";
import { footerNavList } from "../../../utils/nav";

export const Nav = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-6 lg:gap-x-12 xl:gap-x-8 2xl:gap-x-16 items-start w-full lg:justify-around">
      {footerNavList.map((navItem) => (
        <NavList
          title={navItem.title}
          subNavList={navItem.subNavList}
          key={navItem.title}
        />
      ))}
    </div>
  );
};

const NavList: FC<NavItem> = ({ title, subNavList }) => {
  return (
    <div className="flex flex-col gap-3 items-start w-max">
      <p className="uppercase font-semibold text-support-black text-base leading-4 tracking-[1.5%]">
        {title}
      </p>
      <div className="flex flex-col gap-3 items-start w-full">
        {subNavList.map((subNavItem) => (
          <Link
            to={subNavItem.to}
            key={subNavItem.title}
            className="text-[15px] text-support-black w-max"
          >
            {subNavItem.title}
          </Link>
        ))}
      </div>
    </div>
  );
};
