import { CustomIcon } from "../UI/Icon";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";

export const Community = () => {
  return (
    <div className="flex flex-col gap-6 items-start w-max">
      <h5 className="uppercase font-semibold text-support-black text-base leading-4 tracking-[1.4px] w-max text-right">
        walk with us
      </h5>
      <div className="flex gap-3 items-start">
        <Link to={"https://www.instagram.com/accessjupit/"} target="_blank">
          <CustomIcon
            color="text-support-black"
            icon={<InstagramIcon fontSize="medium" />}
          />
        </Link>

        <Link to={"https://x.com/accessjupit"} target="_blank">
          <CustomIcon
            color="text-support-black"
            icon={<TwitterIcon fontSize="medium" />}
          />
        </Link>

        <CustomIcon
          color="text-support-black"
          icon={<FacebookIcon fontSize="medium" />}
        />
      </div>
    </div>
  );
};
