import { Community } from "./Community";
import { FooterHero } from "./Hero";
import { Nav } from "./Nav";
import JupitLogo from "../../../assets/jupit-logo-blue.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-hover px-5 py-14 lg:p-20 lg:px-14 2xl:px-16 grid grid-cols-1 gap-10 lg:gap-10">
      {/* logo */}
      <Link to="/">
        <img
          src={JupitLogo}
          alt="Jupit Logo"
          className="h-8 w-auto md:h-10 object-contain -mb-6 lg:-mb-4"
        />
      </Link>

      <div className="w-full flex flex-col lg:flex-row gap-10 lg:gap-12 items-start lg:justify-between">
        <FooterHero />
        <Nav />
        <Community />
      </div>
      <div className="w-full h-[1px] bg-primary-text-body/10 bg-opacity-10" />
      <div className="flex flex-col w-full gap-y-2">
        <p className="text-paragraph-regular md:text-desktop-body text-left text-support-black">
          &copy; All rights reserved to Jupit Digital Service Ltd.
        </p>
        <p className="text-xs text-support-black text-left">
          Jupit is an alternative payment solution company committed to
          simplifying the use of cryptocurrency for everyday economic
          activities. We do not provide financial or investment advice, nor do
          we endorse or make recommendations concerning any digital assets
          available on our cross-platform. It is important to always Do Your Own
          Research before engaging with any digital asset and to consult a
          qualified financial professional for any financial decisions.
        </p>
        <p className="text-xs text-support-black text-left">
          Additionally, all third-party trademarks presented on this site belong
          to their respective owners. Jupit does not assert representations or
          endorsements regarding the accuracy, reliability, or completeness of
          information accessed through third-party links on this website. Your
          use of such links is entirely at your discretion, and Jupit holds no
          responsibility for any losses or damages resulting from your reliance
          on third-party content.
        </p>
      </div>
    </footer>
  );
};
