import { lazy, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Loading } from "./shared/components/UI/Loading";
import { ScrollToTop } from "./shared/components/UI/ScrollToTop";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

const HomePage = lazy(() => import("./LandingPage/pages/Home"));
const PageNotFound = lazy(() => import("./shared/pages/PageNotFound"));
const CompanyPage = lazy(() => import("./Company/pages"));
const ProductsPage = lazy(() => import("./Products/pages"));
const ResourcesPage = lazy(() => import("./Resources/pages"));
const PoliciesPages = lazy(() => import("./Polices/pages"));

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="company/*" element={<CompanyPage />} />
          <Route path="products/*" element={<ProductsPage />} />
          <Route path="resources/*" element={<ResourcesPage />} />
          <Route path="policies/*" element={<PoliciesPages />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ScrollToTop>
    </Suspense>
  );
}
