import scanImage from "../../../assets/Jupit_App_Download.png";
import { CustomIcon } from "../UI/Icon";
import AppleIcon from "@mui/icons-material/Apple";

export const FooterHero = () => {
  return (
    <div className="flex flex-col justify-center items-start gap-6 w-max flex-none">
      {/* scan */}
      <div className="flex gap-[18px] lg:gap-[15px] items-center">
        <img
          src={scanImage}
          alt="Scan Me"
          className="w-[90px] h-[90px] lg:w-20 lg:h-20"
        />
        <div className="flex flex-col items-start gap-[px]">
          <p className="text-support-black text-paragraph-regular">
            Scan To Download
          </p>
          <p className="text-support-black text-paragraph-regular">
            IOS & Android
          </p>
          <div className="flex gap-4 items-start">
            <CustomIcon
              color="text-support-black"
              icon={<AppleIcon fontSize="small" />}
            />
            <CustomIcon
              color="text-support-black"
              icon={
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="m12.954 11.616 2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462 3.074-1.729c.6-.336.929-.812.929-1.34 0-.527-.329-1.004-.928-1.34l-2.783-1.563-3.133 3.132 2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09.312 0 .637-.092.968-.276l9.255-5.197-2.666-2.67z"></path>
                </svg>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
