import { useState } from "react";
import { Sidebar } from "./Sidebar";
import JupitLogo from "../../assets/jupit-logo-blue.svg";
import { Link } from "react-router-dom";
import { navList } from "../../utils/nav";
import { DesktopNavItem } from "./DesktopNavItem";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

export const Navbar = () => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  return (
    <nav className="w-full h-20 px-5 py-4 pt-8 md:px-20 bg-white flex items-center justify-between">
      <Link to="/">
        <img src={JupitLogo} alt="Jupit logo" className="h-8 w-auto md:h-10" />
      </Link>

      <span
        onClick={() => {
          setOpenSidebar(true);
        }}
        className="text-primary-text-head lg:hidden cursor-pointer"
      >
        <MenuIcon />
      </span>

      <ul className="hidden lg:flex gap-6 items-start">
        {navList.map((navItem) => (
          <DesktopNavItem
            key={navItem.title}
            title={navItem.title}
            subNavList={navItem.subNavList}
          />
        ))}
      </ul>
      <div className="hidden lg:flex items-center gap-6 invisible">
        <Link
          to="sign-in"
          className="text-primary-royal text-sm font-medium leading-4 text-center capitalize px-4 py-3"
        >
          Log in
        </Link>
        <Link
          to="sign-up"
          className="bg-primary-royal text-white text-xs font-medium leading-4 text-center capitalize px-4 py-2 flex gap-2 items-center rounded-lg"
        >
          <span>Sign up</span>
          <PersonAddAltOutlinedIcon fontSize="medium" />
        </Link>
      </div>
      {openSidebar && (
        <Sidebar
          onClose={() => {
            setOpenSidebar(false);
          }}
        />
      )}
    </nav>
  );
};
