import { FC, ReactNode } from "react";
import { SidebarProps } from "../../types/interfaces";
import { createPortal } from "react-dom";
import { navList } from "../../utils/nav";
import { MobileNavItem } from "./MobileNavItem";
import { Link } from "react-router-dom";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PlayStore from "../../assets/Google_Play_Store_badge_EN.png";
import AppStore from "../../assets/Download_on_the_App_Store_Badge.png";
import JupitLogo from "../../assets/jupit-logo-blue.svg";
import CloseIcon from "@mui/icons-material/Close";

export const Sidebar: FC<SidebarProps> = ({ onClose }) => {
  const content: ReactNode = (
    <div className="fixed top-0 w-screen h-screen bg-white overflow-hidden z-[9999] flex flex-col justify-start items-start">
      <nav className="w-full px-5 py-4 pt-8 h-20 bg-white flex items-center justify-between">
        <Link to="/">
          <img
            src={JupitLogo}
            alt="Jupit logo"
            className="h-8 w-auto md:h-10"
          />
        </Link>

        <span
          onClick={onClose}
          className="text-primary-text-head cursor-pointer"
        >
          <CloseIcon />
        </span>
      </nav>
      <div className="p-6 w-full h-full">
        <div className="h-auto w-full py-4 flex flex-col gap-2 bg-white">
          {/* content */}
          <div className="flex flex-col items-start gap-10">
            <ul className="flex flex-col gap-6 items-start justify-center w-full">
              {navList.map((navItem) => (
                <MobileNavItem
                  title={navItem.title}
                  subNavList={navItem.subNavList}
                  key={navItem.title}
                  closeSidebar={{ close: onClose }}
                />
              ))}
            </ul>
            {/* cta */}
            <div className="flex flex-col justify-center items-start gap-6 w-full invisible">
              <Link
                to="sign-in"
                className="text-primary-royal text-sm font-medium leading-4 text-center capitalize px-4 py-3 flex justify-center items-center w-full"
              >
                Login
              </Link>
              <Link
                to="sign-up"
                className="bg-primary-royal text-white text-sm font-medium leading-4 text-center capitalize px-4 py-3 flex justify-center gap-2 items-center rounded-lg w-full "
              >
                <span>Sign up</span>
                <PersonAddAltOutlinedIcon fontSize="medium" />
              </Link>
            </div>
          </div>

          {/* download */}
          <div className="flex flex-col gap-4 items-start mt-6 invisible">
            <h5 className="text-black flex items-center capitalize font-bold text-base leading-4">
              Download our app
            </h5>
            <div className="flex items-start gap-4">
              <Link to="playstore" className="w-[135px] h-10">
                <img
                  src={PlayStore}
                  alt="play store"
                  className="w-full h-full object-contain"
                />
              </Link>
              <Link to="playstore" className="w-[135px] h-10">
                <img
                  src={AppStore}
                  alt="app store"
                  className="w-full h-full object-contain"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(
    content,
    document.getElementById("sidebar") as HTMLElement
  );
};
