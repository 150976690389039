import { FC, Fragment, useEffect } from "react";
import { LayoutProps } from "../../../types/interfaces";
import { useLocation } from "react-router-dom";

export const ScrollToTop: FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  return <Fragment>{children}</Fragment>;
};
