import { NavItem } from "../types/interfaces";

export const navList: NavItem[] = [
  {
    title: "company",
    subNavList: [
      {
        title: "About us",
        to: "/company",
      },
      {
        title: "Careers",
        to: "/company/careers",
      },
      {
        title: "Contact us",
        to: "/company/contact",
      },
    ],
  },
  {
    title: "products",
    subNavList: [
      {
        title: "Crypto Exchange",
        to: "/products/crypto",
      },
      {
        title: "Gift card Exchange",
        to: "/products/gift-card",
      },
      {
        title: "Crypto debit card",
        to: "/products/usd-card",
      },
      {
        title: "OTC",
        to: "/products/otc",
      },
    ],
  },
  {
    title: "resources",
    subNavList: [
      {
        title: "Blog",
        to: "/resources/blog",
      },
      {
        title: "FAQs",
        to: "/resources/faq",
      },
    ],
  },
];

export const footerNavList: NavItem[] = [
  {
    title: "Home",
    subNavList: [
      {
        title: "About",
        to: "/company",
      },
      {
        title: "Careers",
        to: "/company/careers",
      },
      {
        title: "Contact Us",
        to: "/company/contact",
      },
    ],
  },
  {
    title: "product",
    subNavList: [
      {
        title: "Crypto Exchange",
        to: "/products/crypto",
      },
      {
        title: "Gift Card Exchange",
        to: "/products/gift-card",
      },
      {
        title: "Crypto Debit Card",
        to: "/products/usd-card",
      },
      {
        title: "OTC",
        to: "/products/otc",
      },
    ],
  },
  {
    title: "legal",
    subNavList: [
      {
        title: "Terms of Services",
        to: "policies/terms-of-service",
      },
      {
        title: "Privacy Policy",
        to: "policies/privacy-policy",
      },
      {
        title: "AML Policy",
        to: "policies/aml-policy",
      },
    ],
  },
  {
    title: "resources",
    subNavList: [
      {
        title: "Blog",
        to: "/resources/blog",
      },
      {
        title: "FAQs",
        to: "/resources/faq",
      },
    ],
  },
];
