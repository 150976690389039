import { FC, useState } from "react";
import { CloseSidebar, NavItem } from "../../types/interfaces";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface MobileNavItemProps extends NavItem {
  closeSidebar: CloseSidebar;
}

export const MobileNavItem: FC<MobileNavItemProps> = ({
  title,
  subNavList,
  closeSidebar,
}) => {
  const [openSubList, setOpenSubList] = useState<boolean>(false);

  return (
    <li className="flex flex-col w-full items-start">
      <div
        className="flex w-full justify-between items-center p-3"
        onClick={() => {
          setOpenSubList(!openSubList);
        }}
      >
        <span className="capitalize text-base leading-4 font-medium text-support-black">
          {title}
        </span>
        {openSubList ? (
          <span className="text-support-black">
            <KeyboardArrowUpIcon />
          </span>
        ) : (
          <span className="text-support-black">
            <KeyboardArrowDownIcon />
          </span>
        )}
      </div>
      <div className="flex flex-col w-full rounded-lg">
        {openSubList &&
          subNavList.map((subNavItem) => (
            <Link
              to={subNavItem.to}
              key={subNavItem.title}
              onClick={closeSidebar.close}
              className="text-sm leading-[18px] tracking-[1.4px] font-medium text-primary-text-body capitalize p-4 bg-white hover:bg-hover flex items-center"
            >
              {subNavItem.title}
            </Link>
          ))}
      </div>
    </li>
  );
};
